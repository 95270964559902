import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import YAMLData from "../../content/play.yaml"

const PlayPage = ({ data }) => (
  <Layout>
    <SEO
      title="Anja's fun projects"
      description="Take a look at what Anja creates in her free time - just for fun."
      image={data.metaImage.childImageSharp.fixed.src}
    />
    <div className="main-container">
      <h1>Play</h1>
      <p className="subtext-h1">
        Here you can check out what I'm doing in my spare time: learn new stuff,
        play around, discover and just do things for fun.
      </p>
    </div>
    <div className="play-container">
      <div className="masonry">
        {YAMLData.map(({ path, teaser }, i) => {
          const img = data.images.edges.find(
            ({ node }) => node.name === teaser.image
          )
          return (
            <Link
              to={`/play/${path}`}
              className={`masonry-link--${teaser.class}`}
              key={i}
            >
              <img
                className="masonry-img"
                src={img.node.childImageSharp.fluid.src}
                srcSet={img.node.childImageSharp.fluid.srcSet}
                sizes={img.node.childImageSharp.fluid.sizes}
                alt="masonry_image"
              />
            </Link>
          )
        })}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query AllFilesToPathPlay {
    images: allFile(filter: { relativePath: { regex: "/play/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    metaImage: file(
      relativePath: { regex: "/play/daily_UI_001/Daily_UI_001_gross/" }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          src
        }
      }
    }
  }
`

export default PlayPage
